import Vue from 'vue/dist/vue.esm'

Vue.component('rsvp', {
  props: ['eventId', 'initialStatus'],
  mounted() {
    console.log(this.initialStatus)
  },
  data() {
    return {
      rsvped: this.initialStatus === '1'
    }
  },
  template: `
  <div>
    <a v-if="! rsvped" class="button hollow icon" v-on:click.prevent="doRsvp"><i class="fas fa-calendar-plus" aria-hidden="true" />RSVP</a>
    <div v-if="rsvped">
      You're coming!
      <br>
      <a class="button  icon" v-on:click.prevent="undoRSVP"><i class="fas fa-calendar-plus" aria-hidden="true" />Cancel</a>
    </div>
  </div>
  `,
  computed: {

  },
  methods: {
    doRsvp() {
      this.rsvped = true
      const headers = new Headers({
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
      fetch(`/rsvps?event_id=${this.eventId}`, {
          method: 'POST',
          headers: headers
      })
    },
    undoRSVP() {
      this.rsvped = false
      const headers = new Headers({
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
      fetch(`/rsvps/remove?event_id=${this.eventId}`, {
          method: 'DELETE',
          headers: headers
      })
    }
  }
})
