require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

require('modules/rsvp_toggle')


import { pageSetup } from 'modules/page_setup';

document.addEventListener('turbolinks:load', pageSetup)