import Vue from 'vue/dist/vue.esm'

export const pageSetup = () => {
  window.MOM = {}
  window.MOM.mainVue = new Vue({
    el: '#app',
    mounted() {
    }
  })

}